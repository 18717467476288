// @flow

import type { Node } from 'react'
import React from 'react'
import SeoPageTemplate from '../scenes/seo-pages'

function SeoFortMyersBeachRentalsOwner(): Node {
  return <SeoPageTemplate title="Fort Myers Beach Rentals By Owner" />
}

export default SeoFortMyersBeachRentalsOwner
